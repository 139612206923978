<template>
  <div v-loading="fetchLoading" class="finance_main">
    <div class="right_btns">
      <!-- <qzf-video vid="4467be5374b10cc543190b3e6adaf19d_4"></qzf-video> -->

      <qzf-period v-model:period="this.startAccountPeriod2" @change="getList2" style="width:100px" :hidden="true" v-if="this.currentPeriod"></qzf-period>
      <!-- <qzf-button button_code="kjbb_sb" jz="false" v-if="!this.startAccountPeriod" type="primary" @success="shenbao" size="small" v-loading.fullscreen.lock="fullscreenLoading"   >
        <el-icon><List /></el-icon> <span  >申报</span>
      </qzf-button> -->
      <qzf-button button_code="kjbb_scbb" v-if="!this.startAccountPeriod || (this.startAccountPeriod2 == this.currentPeriod)" type="primary" @success="saveBiao" size="small" :loading="loading"   >
        <el-icon><HelpFilled /></el-icon> <span  >取数</span>
      </qzf-button>
      <el-button v-if="!this.paramCheck.comId" size="small" @click="printing()" type="success" :disabled="!$buttonStatus('kjbb_dy')">
        <el-icon><Printer /></el-icon> <span  >打印</span>
      </el-button>
      <el-button  v-if="!this.paramCheck.comId" size="small" @click="downLoad()" type="primary" :disabled="!$buttonStatus('kjbb_dc')" >
        <el-icon><FolderOpened /></el-icon> <span  >导出</span>
      </el-button>
      <!-- <settings v-if="!this.startAccountPeriod " name="设置" :type="['code_zcfz_cfl']"></settings> -->
    </div>
    <!-- 小企业 -->
    <div v-if="type == '小企业会计准则2013版'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb' && this.tableName != 'kj_xjllb'">
          <zcfzTable ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></zcfzTable>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_xjllb'">
          <lrbTable title="利润表(季报)" typeName='会计报表' ref="lrbTable" :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></lrbTable>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbTable title="利润表(月报)" typeName='会计报表' ref="lrbTableMon" :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbTable>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <!-- <lrbYTable title="利润统计表" ref="lrbYTable" :startAccountPeriod="startAccountPeriod"></lrbYTable> -->
          <lrbYTable title="利润统计表" typeName='会计报表' ref="lrbYear" :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb' && this.startAccountPeriod2">
          <cashTable title="现金流量表(月报)" typeName='会计报表' ref="cashMon" :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'" >
          <cashTable title="现金流量表(季报)" typeName='会计报表' ref="cash" :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></cashTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 未执行新金融准则的一般企业 -->
    <div v-if="type == '一般企业会计准则'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <zcfzTableTwo ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></zcfzTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2" v-if="this.tableName != 'kj_zcfz'&& this.tableName != 'kj_xjllb'">
          <lrbTableTwo title="利润表(季报)" ref="lrbTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></lrbTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbTableTwo title="利润表(月报)" ref="lrbTableMon"  typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableTwo ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTableTwo>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb' && this.startAccountPeriod2">
          <!-- <cashTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable> -->
          <cashQuarterTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></cashQuarterTable>
          <!-- <cashTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable> -->
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 企业会计制度 -->
    <div v-if="type == '企业会计制度'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <zcfzTableThree ref="zcfzTable"  typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></zcfzTableThree>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2" v-if="this.tableName != 'kj_zcfz'&& this.tableName != 'kj_xjllb'">
          <lrbTableThree title="利润表(季报)"  typeName='会计报表' ref="lrbTable" :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></lrbTableThree>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbTableThree title="利润表(月报)"  typeName='会计报表' ref="lrbTableMon" :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbTableThree>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableThree title="利润统计表" ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTableThree>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb' && this.startAccountPeriod2">
          <cashTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></cashTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 已执行新金融准则的一般企业 -->
    <div v-if="type == '一般企业会计准则(已执行新金融准则)'">
      <el-tabs v-model="activeName" style="height: 100%;"  @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'" >
          <zcfzTableFour ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></zcfzTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2" v-if="this.tableName != 'kj_zcfz'&& this.tableName != 'kj_xjllb'">
          <lrbTableFour title="利润表(季报)" ref="lrbTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></lrbTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbTableFour title="利润表(月报)" ref="lrbTableMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableFour title="利润统计表" ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTableFour>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb' && this.startAccountPeriod2">
          <cashQuarterTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
          <!-- <cashTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable> -->
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></cashQuarterTable>
          <!-- <cashTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable> -->
        </el-tab-pane>
      </el-tabs>
    </div>
      <!-- 一般企业会计准则(全科目) -->
      <div v-if="type == '一般企业会计准则(全科目)'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <zcfzTableTwo ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></zcfzTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2" v-if="this.tableName != 'kj_zcfz'&& this.tableName != 'kj_xjllb'">
          <lrbTableTwo title="利润表(季报)" ref="lrbTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></lrbTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbTableTwo title="利润表(月报)" ref="lrbTableMon"  typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableTwo ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTableTwo>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb' && this.startAccountPeriod2">
          <cashQuarterTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></cashQuarterTable>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 一般企业会计准则(已执行新金融准则)(全科目) -->
    <div v-if="type == '一般企业会计准则(已执行新金融准则)(全科目)'">
      <el-tabs v-model="activeName" style="height: 100%;"  @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'" >
          <zcfzTableFour ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></zcfzTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2" v-if="this.tableName != 'kj_zcfz'&& this.tableName != 'kj_xjllb'">
          <lrbTableFour title="利润表(季报)" ref="lrbTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></lrbTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbTableFour title="利润表(月报)" ref="lrbTableMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableFour title="利润统计表" ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTableFour>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb' && this.startAccountPeriod2">
          <cashQuarterTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></cashQuarterTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 民办非会计报表 -->
    <div v-if="type == '民办非'">
      <el-tabs v-model="activeName" style="height: 100%;"  @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <balanceBusiness ref="zcfzTable" :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></balanceBusiness>
        </el-tab-pane>
        <el-tab-pane label="业务活动表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <businessSheet ref="lrbTableMon" :startAccountPeriod="startAccountPeriod" :comId="comId"></businessSheet>
        </el-tab-pane>
        <el-tab-pane label="业务活动表(季报)" name="2" v-if="this.tableName != 'kj_zcfz'&& this.tableName != 'kj_xjllb'" >
          <businessSeason ref="lrbTable" :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></businessSeason>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb' && this.startAccountPeriod2">
          <businessCash title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></businessCash>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <businessCash title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></businessCash>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 农村合作社 -->
    <div v-if="type == '农业合作社'">
      <el-tabs v-model="activeName" style="height: 100%;"  @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <nchzsZcfz ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></nchzsZcfz>
        </el-tab-pane>
        <el-tab-pane label="盈余及盈余分配表" name="2" v-if="this.tableName != 'kj_zcfz'&& this.tableName != 'kj_xjllb'">
          <nchzsLrb ref="nchzsYyfp" title="盈余及盈余分配表" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></nchzsLrb>
        </el-tab-pane>
        <!-- <el-tab-pane label="盈余及盈余分配表(季报)" name="2">
          <nchzsLrb ref="lrbTable" title="盈余及盈余分配表(季报)"></nchzsLrb>
        </el-tab-pane> -->
        <el-tab-pane label="成员权益变动表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <nchzsCyqy ref="nchzsCyqy" title="成员权益变动表" :startAccountPeriod="startAccountPeriod" :comId="comId"></nchzsCyqy>
        </el-tab-pane>
        
      </el-tabs>
    </div>
      <el-dialog
        :title="dayinType == 'print' ? '请选择打印的报表' : '请选择导出的报表'"
        v-model="dialogVisible"
        width="60%"
        :before-close="handleClose"
      >
      <div>
        <span style="font-size: 13px;">是否打印封面：</span>
        <el-switch v-model="fm" :active-value="1" :inactive-value="0" /> 
      </div>
      <div>
        <span style="font-size: 13px;">金额是否带千分符：</span>
        <el-switch v-model="thousandSep" /> 
      </div>
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <el-checkbox-group
        v-if="type == '民办非'"
        v-model="checkedCities2"
        @change="handleCheckedCitiesChange"
      >
        <el-checkbox v-for="city in cityOptions2" :label="city" :key="city"
          >{{city}}</el-checkbox
        >
      </el-checkbox-group>

      <el-checkbox-group
        v-else-if="type == '农业合作社'"
        v-model="checkedCities3"
        @change="handleCheckedCitiesChange"
      >
        <el-checkbox v-for="city in cityOptions3" :label="city" :key="city"
          >{{city}}</el-checkbox
        >
      </el-checkbox-group>

      <el-checkbox-group
        v-else
        v-model="checkedCities"
        @change="handleCheckedCitiesChange"
      >
        <el-checkbox v-for="city in cityOptions" :label="city" :key="city"
          >{{city}}</el-checkbox
        >
      </el-checkbox-group>
      <el-form v-if="dayinType == 'print' && !type.match('一般企业会计准则')">
        <el-form-item label="上偏移量(mm)：">
          <el-input  type="number" v-model.number="top" placeholder="请输入" size="small" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="左偏移量(mm)：">
          <el-input  type="number" v-model.number="right" placeholder="请输入" size="small" style="width:200px"></el-input>
        </el-form-item>
      </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="makeSurePrint">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
</template>

<script>
// 小企业
import zcfzTable from "./tables/zcfzTable.vue"
import lrbTable from "./tables/lrbTable.vue"
import lrbYTable from "./tables/lrbYTable.vue"

// 未执行新金融准则
import zcfzTableTwo from "./tables/zcfzTable2.vue"
import lrbTableTwo from "./tables/lrbTable2.vue"
import lrbYTableTwo from "./tables/lrbYTable2.vue"

// 企业会计制度
import zcfzTableThree from "./tables/zcfzTable3.vue"
import lrbTableThree from "./tables/lrbTable3.vue"
import lrbYTableThree from "./tables/lrbYTable3.vue"

// 已执行新金融准则
import zcfzTableFour from "./tables/zcfzTable4.vue"
import lrbTableFour from "./tables/lrbTable4.vue"
import lrbYTableFour from "./tables/lrbYTable4.vue"

// 民办非
import balanceBusiness from './tables/balanceBusiness' //民办非资产负债
import businessSheet from './tables/businessSheet' //民办非业务活动表（月报）
import businessSeason from './tables/businessSeason' //民办非业务活动表（季报）
import businessCash from './tables/businessCash.vue' //民办非现金流量表
// 农村合作社
import nchzsZcfz from "./tables/nchzsZcfz.vue"
import nchzsLrb from "./tables/nchzsLrb.vue"
import nchzsCyqy from "./tables/nchzsCyqy.vue"


// 现金流量表
import cashTable from "./tables/cashTable.vue"
import cashQuarterTable from "./tables/cashQuarterTable"

import { report,exportReport } from "@/api/printSet"
import { createKj } from '@/api/taxCalculation.js'
export default {
  name:'finance',
  components:{
    zcfzTable,
    lrbTable,
    lrbYTable,
    cashTable,
    cashQuarterTable,

    zcfzTableTwo,
    lrbTableTwo,
    lrbYTableTwo,

    zcfzTableThree,
    lrbTableThree,
    lrbYTableThree,

    zcfzTableFour,
    lrbTableFour,
    lrbYTableFour,

    balanceBusiness,
    businessSheet,
    businessSeason,
    businessCash,

    nchzsZcfz,
    nchzsLrb,
    nchzsCyqy
    
  },
  data() {
    return {
      type:this.$store.getters["user/comInfo"].comKj,
      activeName:'1',
      comId:0,
      fetchLoading:false,
      listQuery:{
        zcfz:1,
        lrb:1,
        xjllb:1
      },
      loading:false,
      startAccountPeriod:'',
      startAccountPeriod2:this.$store.getters["user/comInfo"].period,
      currentPeriod:this.$store.getters["user/comInfo"].period,
      tableName:'',
      bookNames:'',
      mqy:'',
      fm: 0,
      dialogVisible:false,
      cityOptions:['资产负债表', '利润表(季报)', '利润表(月报)', '利润统计表','现金流量表(月报)','现金流量表(季报)'],
      cityOptions2:['资产负债表', '业务活动表(月报)', '业务活动表(季报)','现金流量表(月报)','现金流量表(季报)'],
      cityOptions3:['资产负债表', '盈余及盈余分配表', '成员权益变动表'],


      checkAll: false,
      checkedCities: ['资产负债表','利润表(月报)','现金流量表(月报)'],
      checkedCities2:['资产负债表', '业务活动表(月报)', '业务活动表(季报)'],
      checkedCities3:['资产负债表', '盈余及盈余分配表', '成员权益变动表'],

      isIndeterminate: true,
      tableName2:"",
      source:"",
      dayinType:"",
      top:0,
      right:0,
      isOld:this.$store.getters['user/comInfo'].isOld,
      thousandSep:true
  }
  },
  props: {
    paramCheck:{
      default:{},
      type:Object
    },
  },
  watch:{
    paramCheck(newValue,oldValue){
      this.comId = newValue.comId
      this.startAccountPeriod = newValue.period
      this.$nextTick(() => {
        this.getList()
      });
    },
    immediate:true,
    deep:true,
  },
  mounted() {
    if(this.$store.getters["commons/params"].tableName == 'kj_lrb' && this.type != '农业合作社'){
      this.$refs.lrbTable.getList()
    }else if(this.$store.getters["commons/params"].tableName == 'kj_xjllb'){
      if(this.paramCheck.comId){
        this.$refs.cashMon.getList()
      }else{
        this.$refs.cash.getList()
      }
    }else if (this.$store.getters["commons/params"].tableName == 'kj_zcfz'){
      this.$refs.zcfzTable.getList()
    }else if (this.$store.getters["commons/params"].tableName == 'kj_lrb' && this.type == '农业合作社'){
      this.$refs.nchzsYyfp.getList()
    }
  },
  deactivated(){
    if(!this.$getCachedViews("finance")){//销毁 mitt bus
      this.$bus.off("financeUpdate")
    }
  },
  beforeUnmount(){
    if(!this.$getCachedViews("finance")){//销毁 mitt bus
      this.$bus.off("financeUpdate")
    }
  },
  created(){
    if(this.paramCheck.comId){
      this.startAccountPeriod = this.paramCheck.period
      this.comId = this.paramCheck.comId,
      this.type = this.paramCheck.ComKj
      // 初始化
    }else if(this.$store.getters["commons/params"].type){
      this.type = this.$store.getters["commons/params"].type
      this.startAccountPeriod = this.$store.getters["commons/params"].startAccountPeriod
      this.comId = this.$store.getters["commons/params"].comId
      this.tableName = this.$store.getters["commons/params"].tableName
      this.source = 'swcsh'
    }else{
      this.type = this.$store.getters["user/comInfo"].comKj
    }
    if(this.$store.getters["commons/params"].tableName == 'kj_lrb'){
      this.activeName = '2'
    }else if(this.$store.getters["commons/params"].tableName == 'kj_xjllb'){
      if(this.paramCheck.comId){
        this.activeName = '5'
      }else{
        this.activeName = '6'
      }
    }
    this.initBus()

  },
  methods:{
    initBus(){
      this.$bus.off("financeUpdate")
      this.$bus.on("financeUpdate", (val) => {
        // 一键生成凭证的时候的防止结转损益加锁失败
        this.voucherInit()
      });
      this.$bus.off("accountSystemUpdate")
      this.$bus.on("accountSystemUpdate", (val) => {
        this.type = this.$store.getters["user/comInfo"].comKj
      });
    },
    voucherInit(){
      this.$refs.zcfzTable?.getList()
    },
    saveBiao(){
      this.loading = true
      createKj(this.listQuery).then(res=>{
        if(res.data.data.subjectStatus == true){
          this.$notify.error({
            title: '提示',
            message: '银行现金金额小于0',
            duration: 0
          });
        }
        if(res.data.data.zcLessThen0 == true){
          this.$notify.error({
            title: '提示',
            message: '资产合计金额小于0',
            duration: 0,
            offset: 75,
          });
        }
        // if(res.data.data.zcfzStatus == true){
        //   this.$notify.error({
        //     title: '提示',
        //     message: '资产负债不平衡',
        //     duration: 0,
        //     offset: 150,
        //   });
        // }
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          if(this.type == '农业合作社'){
            this.$refs.nchzsYyfp.getList()
            this.$refs.nchzsCyqy.getList()
          }
          this.$refs.zcfzTable.getList()
          this.$refs.lrbTable.getList()
          this.$refs.lrbTableMon.getList()
          if(this.type != '农村合作社' && this.type != '民办非'){
            this.$refs.lrbYear.getList()
          }
          this.$refs.cashMon.getList()
          this.$refs.cash.getList() 
        }
      })
    },
    handleClick(tab){
      if(tab.props.label == '资产负债表'){
        this.$refs.zcfzTable.getList()
      }else if(tab.props.label == '利润表(季报)' || tab.props.label == '业务活动表(季报)'){
        this.$refs.lrbTable.getList()
      } else if(tab.props.label == '利润表(月报)' || tab.props.label == '业务活动表(月报)'){
        this.$refs.lrbTableMon.getList()
      }else if(tab.props.label == '利润统计表'){
        this.$refs.lrbYear.getList()
      }else if(tab.props.label == '现金流量表(月报)'){
        this.$refs.cashMon.getList()
      }else if(tab.props.label == '现金流量表(季报)'){
        this.$refs.cash.getList()
      }else if(tab.props.label == '盈余及盈余分配表'){
        this.$refs.nchzsYyfp.getList()
      }else if(tab.props.label == '成员权益变动表'){
        this.$refs.nchzsCyqy.getList()
      }
    },
    printing(){
      this.dayinType = 'print'
      // 初始化的时候
      if(this.tableName){
        if(this.tableName == "kj_lrb"){
          this.tableName2 = "kj_lrb_q"
        }else if(this.tableName == "kj_xjllb"){
          this.tableName2 = "kj_xjllb_q"
        }else if(this.tableName == "kj_zcfz"){
          this.tableName2 = "kj_zcfz"
        }
        let param = {
          comIds:[this.comId],
          period:this.startAccountPeriod,
          bookNames:[this.tableName2],
          from:'kjbb',
          itemName:"swcsh",
        }
        report(param).then(res=>{
          if(res.data.msg == "success"){
            window.open(res.data.data.url)
          }
        })
      }else{
        this.top = 0;
        this.right = 0
        this.dialogVisible = true
      }
    },
    downLoad(){
      this.dayinType = 'export'
      // 初始化的时候
      if(this.tableName){
        if(this.activeName == '1'){
          this.bookNames = 'gs_cwbb'
        }else if(this.activeName == '2'){
          this.bookNames = 'gs_cwbb'
          this.mqy = 'q'
        }else if(this.activeName == '3'){
          this.bookNames = 'gs_cwbb'
          this.mqy = 'm'
        }else if(this.activeName == '4'){
          this.bookNames = 'gs_cwbb'
          this.mqy = 'y'
        }else if(this.activeName == '5'){
          this.bookNames = 'gs_cwbb'
          this.mqy = 'm'
        }else if(this.activeName == '6'){
          this.bookNames = 'gs_cwbb'
          this.mqy = 'q'
        }
        let param = {
          comIds:[this.$store.getters['user/comInfo'].comId],
          period:this.$store.getters['user/comInfo'].period,
          bookNames:[this.bookNames],
          mqy:this.mqy
        }
        if(this.startAccountPeriod2){
          param.period = this.startAccountPeriod2
        }
        // 初始化报表的导出
        if(this.tableName){
          param.comIds = [this.comId]
          param.period = this.startAccountPeriod
          if(this.tableName == "kj_lrb" ){
            param.itemName = "swcsh_lrb"
          }else if(this.tableName == "kj_xjllb"){
            param.itemName = "swcsh_xjllb"
          }else{
            param.itemName = "swcsh_zcfzb"
          }
        }
        exportReport(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
      }else{
        this.dialogVisible = true
      }
    },
    // 选择打印财务报表的
    handleCheckAllChange(val) {
      //console.log(val);
      if(this.type == '民办非'){
        this.checkedCities2 = val ? this.cityOptions2 : []
      }else if(this.type == '农业合作社'){
        this.checkedCities3 = val ? this.cityOptions3 : []
      }else{
        this.checkedCities = val ? this.cityOptions : []
      }
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cityOptions.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cityOptions.length
    },
    makeSurePrint(){
      let bookNames = []
      if(this.type == '民办非'){
        this.checkedCities = this.checkedCities2
      }else if(this.type == '农业合作社'){
        this.checkedCities = this.checkedCities3
      }else{
        this.checkedCities = this.checkedCities
      }
      if(this.checkedCities.length == 0){
        this.$qzfMessage('请至少选择一个',2)
        return
      }
      this.checkedCities.map(v=> {
        if(v == '资产负债表'){
          bookNames.push('kj_zcfz')
        }else if(v == '利润表(季报)' || v == '业务活动表(季报)' || v == '盈余及盈余分配表'){
          bookNames.push('kj_lrb_q')
        }else if(v == '利润表(月报)' || v == '业务活动表(月报)' || v == '成员权益变动表'){
          bookNames.push('kj_lrb_m')
        }else if(v == '利润统计表'){
          bookNames.push('kj_lrb_y')
        }else if(v == '现金流量表(月报)'){
          bookNames.push('kj_xjllb_m')
        }else if(v == '现金流量表(季报)'){
          bookNames.push('kj_xjllb_q')
        }
      })
      if(this.dayinType == 'print'){
        if(this.top > 50){
          this.$qzfMessage('上偏移量不可超过50',1)
          return
        }
        if(this.right > 25){
          this.$qzfMessage('左偏移量不可超过25',1)
          return
        }
        let param = {
          comIds:[this.$store.getters['user/comInfo'].comId],
          period:this.$store.getters['user/comInfo'].period,
          bookNames,
          merge:1,
          from:'kjbb',
          top:this.top,
          right:this.right,
          fm: this.fm,
          thousandSep:this.thousandSep
        }
        if(this.startAccountPeriod2){
          param.period = this.startAccountPeriod2
        }
        report(param).then(res=>{
          if(res.data.msg == "success"){
            window.open(res.data.data.url)
          }
        })
      }else{
        if(this.activeName == '1'){
          this.bookNames = 'gs_cwbb'
        }else if(this.activeName == '2'){
          this.bookNames = 'gs_cwbb'
          this.mqy = 'q'
        }else if(this.activeName == '3'){
          this.bookNames = 'gs_cwbb'
          this.mqy = 'm'
        }else if(this.activeName == '4'){
          this.bookNames = 'gs_cwbb'
          this.mqy = 'y'
        }else if(this.activeName == '5'){
          this.bookNames = 'gs_cwbb'
          this.mqy = 'm'
        }else if(this.activeName == '6'){
          this.bookNames = 'gs_cwbb'
          this.mqy = 'q'
        }
         let param = {
          comIds:[this.$store.getters['user/comInfo'].comId],
          period:this.$store.getters['user/comInfo'].period,
          itemNames:bookNames,
          merge:1,
          from:'kjbb',
          bookNames:[this.bookNames],
          mqy:this.mqy,
          thousandSep:this.thousandSep
        }
        if(this.startAccountPeriod2){
          param.period = this.startAccountPeriod2
        }
        exportReport(param).then(res=>{
          if(res.data.msg == "success"){
            window.open(res.data.data.url)
          }
        }) 
      }
    },
    getList(){
      this.$nextTick(()=> {
        this.$refs.zcfzTable.getList()
      });
    },
    getList2(){
      // let options = this.$store.getters['commons/periods']
      // options[0].options.map(v => {
      //   if(v.period == this.startAccountPeriod2){
      //     this.isOld = v.isOld
      //   }
      // })

      this.startAccountPeriod = this.startAccountPeriod2
      this.$nextTick(()=> {
      if(this.activeName == '1'){
        this.$refs.zcfzTable.getList()
      }else if(this.activeName == '2'){
        if(this.type == '农业合作社'){
          this.$refs.nchzsYyfp.getList()
        }else{
          this.$refs.lrbTable.getList()
        }
      } else if(this.activeName == '3'){
        this.$refs.lrbTableMon.getList()
      }else if(this.activeName == '4'){
        if(this.type == '农业合作社'){
          this.$refs.nchzsCyqy.getList()
        }else{
          this.$refs.lrbYear.getList()
        }
      }else if(this.activeName == '5'){
        this.$refs.cashMon.getList()
      }else if(this.activeName == '6'){
        this.$refs.cash.getList()
      }else if(this.activeName == '7'){
        this.$refs.nchzsYyfp.getList()
      }else if(this.activeName == '8'){
        this.$refs.nchzsCyqy.getList()
      }
      });
      
    },
    // 全局的loading
    changeLoading(bol){
      this.fetchLoading = bol
    }
  }
}
</script>

<style lang="scss" scoped>
.right_btns{
  position: absolute;
  top: 7px;
  right: 18px;
  z-index: 99;
}
:deep(.el-tabs__content){
  background-color: #f1f1f1 !important;
}
.finance_main{
  background-color: #f1f1f1;
  position: relative;
}
</style>