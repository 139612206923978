<template>
  <div class="swbb_table_width">
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span></span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:31%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:13%;">第一季度</td>
          <td class="center" style="width:13%;">第二季度</td>
          <td class="center" style="width:13%;">第三季度</td>
          <td class="center" style="width:13%;">第四季度</td>
          <td class="center" style="width:13%;">本年累计</td>
        </tr>
      </table>
      <el-scrollbar :style="{height:contentStyleObj}"> 
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight bg_color" style="width:31%;">一、营业收入</td>
            <td class="center bg_color" style="width:4%;">1</td>
            <qzf-td style="width:13%;" v-model:amount="jd1[0].yearTotal" :disabled="false" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd2[0].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd3[0].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd4[0].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[0].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">减：营业成本</td>
            <td class="center bg_color">2</td>
            <qzf-td style="width:13%;" v-model:amount="jd1[1].yearTotal" :disabled="false" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd2[1].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd3[1].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd4[1].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[1].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_k">税金及附加</td>
            <td class="center bg_color">3</td>
            <qzf-td style="width:13%;" v-model:amount="jd1[2].yearTotal" :disabled="false" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd2[2].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd3[2].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd4[2].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[2].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：消费税</td>
            <td class="center bg_color">4</td>
            <qzf-td style="width:13%;" v-model:amount="jd1[3].yearTotal" :disabled="false" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd2[3].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd3[3].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd4[3].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[3].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">营业税</td>
            <td class="center bg_color">5</td>
            <qzf-td style="width:13%;" v-model:amount="jd1[4].yearTotal" :disabled="false" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd2[4].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd3[4].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd4[4].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[4].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="bg_color left_s">城市维护建设税</td>
            <td class="center bg_color">6</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[5].yearTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="bg_color left_s">资源税</td>
            <td class="center bg_color">7</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[6].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">土地增值税</td>
            <td class="center bg_color">8</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[7].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">城镇土地使用税、房产税、车船税、印花税</td>
            <td class="center bg_color">9</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[8].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">教育附加、矿产资源、排污费</td>
            <td class="center bg_color">10</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[9].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_k">销售费用</td>
            <td class="center bg_color">11</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[10].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：商品维修费</td>
            <td class="center bg_color">12</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[11].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">广告费和业务宣传费</td>
            <td class="center bg_color">13</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[12].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_k">管理费用</td>
            <td class="center bg_color">14</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[13].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：开办费</td>
            <td class="center bg_color">15</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[14].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">业务招待费</td>
            <td class="center bg_color">16</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[15].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">研究费用</td>
            <td class="center bg_color">17</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[16].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_k">财务费用</td>
            <td class="center bg_color">18</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[17].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：利息费用(收入以-号填列)</td>
            <td class="center bg_color">19</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[18].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">加：投资收益</td>
            <td class="center bg_color">20</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[19].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">二、营业利润</td>
            <td class="center bg_color">21</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true"  v-model:amount="jd2[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true"  v-model:amount="jd3[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true"  v-model:amount="jd4[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true"  v-model:amount="yaerTotal[20].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">加：营业外收入</td>
            <td class="center bg_color">22</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[21].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：政府补助</td>
            <td class="center bg_color">23</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[22].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">减：营业外支出</td>
            <td class="center bg_color">24</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[23].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：坏账损失</td>
            <td class="center bg_color">25</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[24].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">无法收回的长期债券投资损失</td>
            <td class="center bg_color">26</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[25].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">无法收回的长期股权投资损失</td>
            <td class="center bg_color">27</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[26].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">自然灾害等不可抗力因素造成的损失</td>
            <td class="center bg_color">28</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[27].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">税收滞纳金</td>
            <td class="center bg_color">29</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[28].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">三、利润总额</td>
            <td class="center bg_color">30</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd2[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd3[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd4[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true"  v-model:amount="yaerTotal[29].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">减：所得税费用</td>
            <td class="center bg_color">31</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[30].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">四、净利润</td>
            <td class="center bg_color">32</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd2[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd3[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd4[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true"  v-model:amount="yaerTotal[31].yearTotal" @change="changeList()"></qzf-td>
          </tr>
        </table>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'lrbYTable',
  props:{
    title:String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      jd1: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd2:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd3:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd4:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      yaerTotal:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化

    }
  },
  created(){
    this.contentStyleObj=this.$getHeight(258)
  },
  methods: {
    changeList(){
      
    },
    getList() {
      gsInfo({tableName: 'kj_lrb',itemName:'year',period:this.startAccountPeriod,comId:this.comId}).then(res=>{
        this.jd1 = res.data.data.info.jd1.length != 0 ? res.data.data.info.jd1 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        this.jd2 = res.data.data.info.jd2.length != 0 ? res.data.data.info.jd2 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        this.jd3 = res.data.data.info.jd3.length!= 0 ? res.data.data.info.jd3 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        this.jd4 = res.data.data.info.jd4.length!= 0 ? res.data.data.info.jd4 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        // 本年累计计算公式 todo
        if (res.data.data.info.jd4.length!= 0) {
          for (let i = 0; i < this.jd4.length; i++) {
            if (res.data.data.info.jd3.length!= 0){
              // if(this.jd4[i].yearTotal == 0){
              //   continue
              // }
              this.jd4[i].yearTotal = this.jd4[i].yearTotal -this.jd3[i].yearTotal
            }
          }
        }

        if (res.data.data.info.jd3.length!= 0) {
          for (let i = 0; i < this.jd3.length; i++) {
            if (res.data.data.info.jd2.length!= 0){
              // if(this.jd3[i].yearTotal == 0){
              //   continue
              // }
              this.jd3[i].yearTotal = this.jd3[i].yearTotal -this.jd2[i].yearTotal
            }else if(res.data.data.info.jd2.length == 0 && res.data.data.info.jd1.length == 0){
              this.jd3[i].yearTotal = this.jd3[i].periodTotal
            }
          }
        }

        if (res.data.data.info.jd2.length!= 0) {
          for (let i = 0; i < this.jd2.length; i++) {
            if (res.data.data.info.jd1.length!= 0){
              if(this.jd2[i].yearTotal == 0){
                continue
              }
              this.jd2[i].yearTotal = this.jd2[i].yearTotal -this.jd1[i].yearTotal
            }
          }
        }
        
        for (let i = 0; i < this.yaerTotal.length; i++) {
          this.yaerTotal[i].yearTotal = ((this.jd1[i].yearTotal?this.jd1[i].yearTotal:0) + (this.jd2[i].yearTotal?this.jd2[i].yearTotal:0) + (this.jd3[i].yearTotal?this.jd3[i].yearTotal:0) + (this.jd4[i].yearTotal?this.jd4[i].yearTotal:0)).toFixed2()*1
        }
      })
      // if(this.initType == '初始化财务'){
      //   gsInfo({tableName: 'kj_lrb',itemName:'year',period:this.startAccountPeriod}).then(res=>{
      //     if(res.data.data.info){
      //       this.list = res.data.data.info
      //     }
      //   })
      // }else{
      //   gsInfo({tableName: 'kj_lrb',itemName:'year'}).then(res=>{
      //     if(res.data.data.info){
      //       this.list = res.data.data.info
      //     }
      //   })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}

.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_t{
  text-indent: 3em;
}
.left_s{
  text-indent: 6em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 3px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>
