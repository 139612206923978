<template>
  <!-- 小企业 利润表-->
  <div class="swbb_table_width">
    <div class="big_box4">
      <div class="top_title3">
        <h4>利润统计表（适用未执行新金融准则的一般企业）</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span></span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:31%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:13%;">第一季度</td>
          <td class="center" style="width:13%;">第二季度</td>
          <td class="center" style="width:13%;">第三季度</td>
          <td class="center" style="width:13%;">第四季度</td>
          <td class="center" style="width:13%;">本年累计</td>
        </tr>
      </table>
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
        <el-scrollbar :style="{height:contentStyleObj}"> 
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight bg_color" style="width:31%;">一、营业收入</td>
            <td class="center bg_color" style="width:4%;">1</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[0].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

          </tr>
          <tr>
            <td class="bg_color left_k">减：营业成本</td>
            <td class="center bg_color">2</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[1].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
            
          </tr>
          <tr>
            <td class="bg_color left_b">税金及附加</td>
            <td class="center bg_color">3</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[2].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_b">销售费用</td>
            <td class="center bg_color">4</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[3].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_b">管理费用</td>
            <td class="center bg_color">5</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[4].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_b">研发费用</td>
            <td class="center bg_color">6</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[5].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_b">财务费用</td>
            <td class="center bg_color">7</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[6].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_k bg_color left_x">其中：利息费用</td>
            <td class="center bg_color">8</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[7].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_k bg_color left_d">利息收入</td>
            <td class="center bg_color">9</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[8].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">加：其他收益</td>
            <td class="center bg_color">10</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[9].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <!-- <tr>
              -->
          <tr>
            <td class="bg_color left_b">投资收益（损失以“—”号填列）</td>
            <td class="center bg_color">11</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[10].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_x">其中：对联营企业和合营企业的投资收益</td>
            <td class="center bg_color">12</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[11].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">公允价值变动收益（损失以“—”号填列）</td>
            <td class="center bg_color">13</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[12].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">资产减值损失（损失以“-”号填列）</td>
            <td class="center bg_color">14</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[13].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">资产处置收益（损失以“-”号填列）</td>
            <td class="center bg_color">15</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[14].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight bg_color">二、营业利润</td>
            <td class="center bg_color">16</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd2[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd3[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd4[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="yaerTotal[15].yearTotal"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="true"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">加：营业外收入</td>
            <td class="center bg_color">17</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[16].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">减：营业外支出</td>
            <td class="center bg_color">18</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[17].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight bg_color">三、利润总额（亏损总额以“—”号填列）</td>
            <td class="center bg_color">19</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd2[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd3[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd4[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="yaerTotal[18].yearTotal"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="true"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">减：所得税费用</td>
            <td class="center bg_color">20</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[19].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight bg_color">四、净利润（净亏损以“—”号填列）</td>
            <td class="center bg_color">21</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd2[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd3[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd4[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="yaerTotal[20].yearTotal" @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="true"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">（一）持续经营净利润（净亏损以“-”号填列）</td>
            <td class="center bg_color">22</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[21].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">（二）终止经营净利润（净亏损以“-”号填列）</td>
            <td class="center bg_color">23</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[22].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>

          <tr>
            <td class="weight bg_color">五、其他综合收益的税后净额</td>
            <td class="center bg_color">24</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd2[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd3[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd4[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;"  v-model:amount="yaerTotal[23].yearTotal" :disabled="true"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="true"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">（一）不能重分类进损益的其他综合收益</td>
            <td class="center bg_color">25</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[24].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_x">1.重新计量设定受益计划变动额</td>
            <td class="center bg_color">26</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[25].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_x">2.权益法下不能转损益的其他利益综合收益</td>
            <td class="center bg_color">27</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[26].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">（二）将重分类进损益的其他综合收益</td>
            <td class="center bg_color">28</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[27].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_x">1.权益法下可转损益的其他综合收益</td>
            <td class="center bg_color">29</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[28].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_x">2.可供出售金融资产公允价值变动损益</td>
            <td class="center bg_color">30</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[29].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_x">3.持有至到期投资重分类可供出售金融资产损益</td>
            <td class="center bg_color">31</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[30].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_x">4.现金流量期损益的有效部分</td>
            <td class="center bg_color">32</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[31].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_x">5.外币财务报表折算差额</td>
            <td class="center bg_color">33</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[32].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[32].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[32].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[32].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[32].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>

          <tr>
            <td class="weight bg_color">六、综合收益总额</td>
            <td class="center bg_color">34</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[33].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd2[33].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd3[33].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd4[33].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[33].yearTotal" :disabled="true"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="true"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight bg_color">七、每股收益</td>
            <td class="center bg_color">35</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[34].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[34].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[34].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[34].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[34].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">（一）基本每股收益</td>
            <td class="center bg_color">36</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[35].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[35].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[35].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[35].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[35].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="bg_color left_k">（二）稀释每股收益</td>
            <td class="center bg_color">37</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[36].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[36].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[36].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[36].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="yaerTotal[36].yearTotal" :disabled="false"  @change="changeList()"></qzf-td>

            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>

        </table>
        </el-scrollbar>
      <!-- </div> -->
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'zcfzTable',
  components: { },
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      jd1: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd2:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd3:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd4:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      yaerTotal:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],

      contentStyleObj:{}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type:0,
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      list: []
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(260)
  },
  methods: {
    changeList(){
      
    },
    getList() {
      gsInfo({tableName: 'kj_lrb',itemName:'year',period:this.startAccountPeriod,comId:this.comId}).then(res=>{
        if(res.data.data.info){
          this.jd1 = res.data.data.info.jd1.length == 37 ? res.data.data.info.jd1 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
          this.jd2 = res.data.data.info.jd2.length == 37 ? res.data.data.info.jd2 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
          this.jd3 = res.data.data.info.jd3.length== 37 ? res.data.data.info.jd3 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
          this.jd4 = res.data.data.info.jd4.length== 37 ? res.data.data.info.jd4 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
          
          // 本年累计计算公式 todo
          if (res.data.data.info.jd4.length!= 0) {
            for (let i = 0; i < this.jd4.length; i++) {
              if (res.data.data.info.jd3.length!= 0){
                if(this.jd4[i].yearTotal == 0){
                  continue
                }
                this.jd4[i].yearTotal = this.jd4[i].yearTotal -this.jd3[i].yearTotal
              }
            }
          }

          if (res.data.data.info.jd3.length!= 0) {
            for (let i = 0; i < this.jd3.length; i++) {
              if (res.data.data.info.jd2.length!= 0){
                if(this.jd3[i].yearTotal == 0){
                  continue
                }
                this.jd3[i].yearTotal = this.jd3[i].yearTotal -this.jd2[i].yearTotal
              }
            }
          }

          if (res.data.data.info.jd2.length!= 0) {
            for (let i = 0; i < this.jd2.length; i++) {
              if (res.data.data.info.jd1.length!= 0){
                if(this.jd2[i].yearTotal == 0){
                  continue
                }
                this.jd2[i].yearTotal = this.jd2[i].yearTotal -this.jd1[i].yearTotal
              }
            }
          }
          
          for (let i = 0; i < this.yaerTotal.length; i++) {
            this.yaerTotal[i].yearTotal = ((this.jd1[i].yearTotal?this.jd1[i].yearTotal:0) + (this.jd2[i].yearTotal?this.jd2[i].yearTotal:0) + (this.jd3[i].yearTotal?this.jd3[i].yearTotal:0) + (this.jd4[i].yearTotal?this.jd4[i].yearTotal:0)).toFixed2()*1
          }
        }
      
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 13px;
    padding-right:20px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}

.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>
