<template>
  <!-- 企业会计制度 利润表-->
  <div class="swbb_table_width">
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span></span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:36%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:12%;">第一季度</td>
          <td class="center" style="width:12%;">第二季度</td>
          <td class="center" style="width:12%;">第三季度</td>
          <td class="center" style="width:12%;">第四季度</td>
          <td class="center" style="width:12%;">本年累计</td>
        </tr>
      </table>
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
        <el-scrollbar :style="{height:contentStyleObj}">

        
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight bg_color" style="width:36%;">一、主营业务收入</td>
            <td class="center bg_color" style="width:4%;">1</td>
            <qzf-td style="width:12%;" :disabled="false" v-model:amount="jd1[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:12%;" :disabled="false" v-model:amount="jd2[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:12%;" :disabled="false" v-model:amount="jd3[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:12%;" :disabled="false" v-model:amount="jd4[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:12%;" :disabled="false" v-model:amount="yaerTotal[0].yearTotal"  @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：主营业务成本</td>
            <td class="center bg_color">2</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[1].yearTotal"  @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">主营业务税⾦及附加</td>
            <td class="center bg_color">3</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false"  v-model:amount="yaerTotal[2].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">⼆、主营业务利润（亏损以“－”号填列）(1-2-3)</td>
            <td class="center bg_color">4</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[3].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：其他业务利润（亏损以“－”号填列</td>
            <td class="center bg_color">5</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[4].yearTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">减：营业费⽤</td>
            <td class="center bg_color">6</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[5].yearTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_b bg_color">管理费⽤</td>
            <td class="center bg_color">7</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[6].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">财务费⽤</td>
            <td class="center bg_color">8</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[7].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">三、营业利润（亏损以“－”号填列）(4+5-6-7-8)</td>
            <td class="center bg_color">9</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[8].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：投资收益（损失以“－”号填列）</td>
            <td class="center bg_color">10</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[9].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">补贴收⼊</td>
            <td class="center bg_color">11</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[10].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">营业外收⼊</td>
            <td class="center bg_color">12</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[11].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：营业外⽀出</td>
            <td class="center bg_color">13</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[12].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">四、利润总额（亏损总额以“－”号填列）(9+10+11+12-13)</td>
            <td class="center bg_color">14</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[13].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：所得税</td>
            <td class="center bg_color">15</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[14].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">五、净利润（净亏损以“－”号填列）(14-15)</td>
            <td class="center bg_color">16</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[15].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">1．出售、处置部⻔或被投资单位所得收益</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[16].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">2．⾃然灾害发⽣的损失</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[17].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">3．会计政策变更增加（或减少）利润总额</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[18].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">4、会计估计变更增加（或减少）利润总额</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[19].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">5、债务重组损失</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[20].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">6、其他</td>
            <qzf-td  :disabled="false" v-model:amount="jd1[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd2[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd3[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="jd4[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="yaerTotal[21].yearTotal" @change="changeList()"></qzf-td>
          </tr>
        </table>
       </el-scrollbar>
      <!-- </div> -->
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'lrbYTable3',
  components: { },
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      jd1: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd2:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd3:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd4:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      yaerTotal:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type:0,
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(260)
  },
  methods: {
    changeList(){
    },
    getList() {
      gsInfo({tableName: 'kj_lrb',itemName:'year',period:this.startAccountPeriod,comId:this.comId}).then(res=>{
        this.jd1 = res.data.data.info.jd1.length != 0 ? res.data.data.info.jd1 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        this.jd2 = res.data.data.info.jd2.length != 0 ? res.data.data.info.jd2 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        this.jd3 = res.data.data.info.jd3.length != 0 ? res.data.data.info.jd3 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        this.jd4 = res.data.data.info.jd4.length != 0 ? res.data.data.info.jd4 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        // 本年累计计算公式 todo

        if (res.data.data.info.jd4.length!= 0) {
          for (let i = 0; i < this.jd4.length; i++) {
            if (res.data.data.info.jd3.length!= 0){
              if(this.jd4[i].yearTotal == 0){
                continue
              }
              this.jd4[i].yearTotal = this.jd4[i].yearTotal -this.jd3[i].yearTotal
            }
          }
        }

        if (res.data.data.info.jd3.length!= 0) {
          for (let i = 0; i < this.jd3.length; i++) {
            if (res.data.data.info.jd2.length!= 0){
              if(this.jd3[i].yearTotal == 0){
                continue
              }
              this.jd3[i].yearTotal = this.jd3[i].yearTotal -this.jd2[i].yearTotal
            }
          }
        }

        if (res.data.data.info.jd2.length!= 0) {
          for (let i = 0; i < this.jd2.length; i++) {
            if (res.data.data.info.jd1.length!= 0){
              if(this.jd2[i].yearTotal == 0){
                continue
              }
              this.jd2[i].yearTotal = this.jd2[i].yearTotal -this.jd1[i].yearTotal
            }
          }
        }
        
        for (let i = 0; i < this.yaerTotal.length; i++) {
          this.yaerTotal[i].yearTotal = ((this.jd1[i].yearTotal?this.jd1[i].yearTotal:0) + (this.jd2[i].yearTotal?this.jd2[i].yearTotal:0) + (this.jd3[i].yearTotal?this.jd3[i].yearTotal:0) + (this.jd4[i].yearTotal?this.jd4[i].yearTotal:0)).toFixed2()*1
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #000;
    font-size: 14px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}

.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>
