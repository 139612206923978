<template>
  <!-- 企业会计制度 利润表-->
  <div class="swbb_table_width">
    <div class="big_box4">
      <div class="top_title3">
        <h4>利润统计表（适用已执行新金融准则的一般企业）</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span></span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:36%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:12%;">第一季度</td>
          <td class="center" style="width:12%;">第二季度</td>
          <td class="center" style="width:12%;">第三季度</td>
          <td class="center" style="width:12%;">第四季度</td>
          <td class="center" style="width:12%;">本年累计</td>

        </tr>
      </table>
      <el-scrollbar :style="{height:contentStyleObj}"> 
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight bg_color" style="width:36%;">一、营业收入</td>
            <td class="center bg_color" style="width:4%;">1</td>
            <qzf-td style="width:12%;" :disabled="false" v-model:amount="list[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:12%;" :disabled="false" v-model:amount="list2[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:12%;" :disabled="false" v-model:amount="list3[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:12%;" :disabled="false" v-model:amount="list4[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:12%;" :disabled="false" v-model:amount="yaerTotal[0].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：营业成本</td>
            <td class="center bg_color">2</td>
            <qzf-td :disabled="false" v-model:amount="list[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[1].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">税金及附加</td>
            <td class="center bg_color">3</td>
            <qzf-td :disabled="false" v-model:amount="list[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[2].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">销售费用</td>
            <td class="center bg_color">4</td>
            <qzf-td :disabled="false" v-model:amount="list[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[3].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">管理费用</td>
            <td class="center bg_color">5</td>
            <qzf-td :disabled="false" v-model:amount="list[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[4].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">研发费用</td>
            <td class="center bg_color">6</td>
            <qzf-td :disabled="false" v-model:amount="list[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[5].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">财务费用</td>
            <td class="center bg_color">7</td>
            <qzf-td :disabled="false" v-model:amount="list[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[6].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">其中：利息费用</td>
            <td class="center bg_color">8</td>
            <qzf-td :disabled="false" v-model:amount="list[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[7].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_d bg_color">利息收入</td>
            <td class="center bg_color">9</td>
            <qzf-td :disabled="false" v-model:amount="list[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[8].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：其他收益</td>
            <td class="center bg_color">10</td>
            <qzf-td :disabled="false" v-model:amount="list[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[9].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <!-- <tr>
              -->
          <tr>
            <td class="left_b bg_color">投资收益（损失以“﹣”号填列）</td>
            <td class="center bg_color">11</td>
            <qzf-td :disabled="false" v-model:amount="list[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[10].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">其中：对联营企业和合营企业的投资收益</td>
            <td class="center bg_color">12</td>
            <qzf-td :disabled="false" v-model:amount="list[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[11].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">以摊余成本计量的金融资产终止确认收益(损失以“﹣”号填列)</td>
            <td class="center bg_color">13</td>
            <qzf-td :disabled="false" v-model:amount="list[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[12].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">净敞口套期收益(损失以“﹣”号填列)</td>
            <td class="center bg_color">14</td>
            <qzf-td :disabled="false" v-model:amount="list[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[13].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">公允价值变动收益（损失以“﹣”号填列）</td>
            <td class="center bg_color">15</td>
            <qzf-td :disabled="false" v-model:amount="list[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[14].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">信用减值损失（损失以“﹣”号填列）</td>
            <td class="center bg_color">16</td>
            <qzf-td :disabled="false" v-model:amount="list[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[15].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">资产减值损失（损失以“﹣”号填列）</td>
            <td class="center bg_color">17</td>
            <qzf-td :disabled="false" v-model:amount="list[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[16].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">资产处置收益（损失以“﹣”号填列）</td>
            <td class="center bg_color">18</td>
            <qzf-td :disabled="false" v-model:amount="list[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[17].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">二、营业利润（亏损以“﹣”号填列）</td>
            <td class="center bg_color">19</td>
            <qzf-td :disabled="true" v-model:amount="list[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list2[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list3[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list4[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="yaerTotal[18].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：营业外收入</td>
            <td class="center bg_color">20</td>
            <qzf-td :disabled="false" v-model:amount="list[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[19].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：营业外支出</td>
            <td class="center bg_color">21</td>
            <qzf-td :disabled="false" v-model:amount="list[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[20].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">三、利润总额（亏损总额以“﹣”号填列）</td>
            <td class="center bg_color">22</td>
            <qzf-td :disabled="true" v-model:amount="list[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list2[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list3[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list4[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="yaerTotal[21].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：所得税费用</td>
            <td class="center bg_color">23</td>
            <qzf-td :disabled="false" v-model:amount="list[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[22].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">四、净利润（净亏损以“﹣”号填列）</td>
            <td class="center bg_color">24</td>
            <qzf-td :disabled="true" v-model:amount="list[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list2[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list3[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list4[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="yaerTotal[23].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">（一）持续经营净利润（净亏损以“﹣”号填列）</td>
            <td class="center bg_color">25</td>
            <qzf-td :disabled="false" v-model:amount="list[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[24].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">（二）终止经营净利润（净亏损以“﹣”号填列）</td>
            <td class="center bg_color">26</td>
            <qzf-td :disabled="false" v-model:amount="list[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[25].yearTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="weight bg_color">五、其他综合收益的税后净额</td>
            <td class="center bg_color">27</td>
            <qzf-td :disabled="true" v-model:amount="list[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list2[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list3[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list4[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="yaerTotal[26].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">（一）不能重分类进损益的其他综合收益</td>
            <td class="center bg_color">28</td>
            <qzf-td :disabled="false" v-model:amount="list[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[27].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">1.重新计量设定受益计划变动额</td>
            <td class="center bg_color">29</td>
            <qzf-td :disabled="false" v-model:amount="list[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[28].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">2.权益法下不能转损益的其他利益综合收益</td>
            <td class="center bg_color">30</td>
            <qzf-td :disabled="false" v-model:amount="list[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[29].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">3.其他权益工具投资公允价值变动</td>
            <td class="center bg_color">31</td>
            <qzf-td :disabled="false" v-model:amount="list[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[30].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">4．企业自身信用风险公允价值变动</td>
            <td class="center bg_color">32</td>
            <qzf-td :disabled="false" v-model:amount="list[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[31].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">（二）将重分类进损益的其他综合收益</td>
            <td class="center bg_color">33</td>
            <qzf-td :disabled="false" v-model:amount="list[32].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[32].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[32].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[32].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[32].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">1.权益法下可转损益的其他综合收益</td>
            <td class="center bg_color">34</td>
            <qzf-td :disabled="false" v-model:amount="list[33].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[33].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[33].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[33].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[33].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">2.其他债权投资公允价值变动</td>
            <td class="center bg_color">35</td>
            <qzf-td :disabled="false" v-model:amount="list[34].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[34].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[34].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[34].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[34].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">3.金融资产重分类计入其他综合收益的金额</td>
            <td class="center bg_color">36</td>
            <qzf-td :disabled="false" v-model:amount="list[35].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[35].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[35].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[35].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[35].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">4.其他债权投资信用减值准备</td>
            <td class="center bg_color">37</td>
            <qzf-td :disabled="false" v-model:amount="list[36].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[36].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[36].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[36].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[36].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">5.现金流量套期储备</td>
            <td class="center bg_color">38</td>
            <qzf-td :disabled="false" v-model:amount="list[37].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[37].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[37].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[37].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[37].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">6.外币财务报表折算差额</td>
            <td class="center bg_color">39</td>
            <qzf-td :disabled="false" v-model:amount="list[38].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[38].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[38].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[38].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[38].yearTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="weight bg_color">六、综合收益总额</td>
            <td class="center bg_color">40</td>
            <qzf-td :disabled="true" v-model:amount="list[39].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list2[39].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list3[39].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list4[39].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="yaerTotal[39].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color" >七、每股收益</td>
            <td class="center bg_color"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          
          <tr>
            <td class="left_k bg_color">（一）基本每股收益</td>
            <td class="center bg_color">41</td>
            <qzf-td :disabled="false" v-model:amount="list[40].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[40].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[40].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[40].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[40].yearTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">（二）稀释每股收益</td>
            <td class="center bg_color">42</td>
            <qzf-td :disabled="false" v-model:amount="list[41].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list2[41].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list3[41].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list4[41].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="yaerTotal[41].yearTotal" @change="changeList()"></qzf-td>
          </tr>

        </table>
       </el-scrollbar>
    </div>
    <!-- <div class="save_box" v-if="title == '利润表(季报)'">
      <el-button @click="saveLrb" type="primary" size="small" plain>保存</el-button>
    </div> -->
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'lybYTable',
  components: { },
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      list: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      list2:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      list3:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      list4:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      yaerTotal:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type:0,
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(260)
  },

  methods: {
    changeList(){
    },
    getList() {
      gsInfo({tableName: 'kj_lrb',itemName:'year',period:this.startAccountPeriod,comId:this.comId}).then(res=>{
        this.list = res.data.data.info.jd1.length != 0 ? res.data.data.info.jd1 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        this.list2 = res.data.data.info.jd2.length != 0 ? res.data.data.info.jd2 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        this.list3 = res.data.data.info.jd3.length != 0 ? res.data.data.info.jd3 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        this.list4 = res.data.data.info.jd4.length != 0 ? res.data.data.info.jd4 : [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
        // 本年累计计算公式 todo

        if (res.data.data.info.jd4.length!= 0) {
          for (let i = 0; i < this.list4.length; i++) {
            if (res.data.data.info.jd3.length!= 0){
              if(this.list4[i].yearTotal == 0){
                continue
              }
              this.list4[i].yearTotal = this.list4[i].yearTotal -this.list3[i].yearTotal
            }
          }
        }

        if (res.data.data.info.jd3.length!= 0) {
          for (let i = 0; i < this.list3.length; i++) {
            if (res.data.data.info.jd2.length!= 0){
              if(this.list3[i].yearTotal == 0){
                continue
              }
              this.list3[i].yearTotal = this.list3[i].yearTotal -this.list2[i].yearTotal
            }
          }
        }

        if (res.data.data.info.jd2.length!= 0) {
          for (let i = 0; i < this.list2.length; i++) {
            if (res.data.data.info.jd1.length!= 0){
              if(this.list2[i].yearTotal == 0){
                continue
              }
              this.list2[i].yearTotal = this.list2[i].yearTotal -this.list[i].yearTotal
            }
          }
        }

        
        for (let i = 0; i < this.yaerTotal.length; i++) {
         this.yaerTotal[i].yearTotal = ((this.list[i].yearTotal?this.list[i].yearTotal:0) + (this.list2[i].yearTotal?this.list2[i].yearTotal:0) + (this.list3[i].yearTotal?this.list3[i].yearTotal:0) + (this.list4[i].yearTotal?this.list4[i].yearTotal:0)).toFixed2()*1
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>
