<template>
  <div class="swbb_table_width">
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
          <h6 class="center" v-if="title == '现金流量表(季报)'"><span>{{periodLrb}}</span></h6>
          <h6 class="center" v-else-if="title == '现金流量表(月报)'"><span>{{periodLrb2}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
          <!-- <span style="font-size:14px;color: red;margin-left: 50%;">*累计金额系统不会取数,申报完成会抓取税局最新金额</span> -->
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:45%;">项目</td>
          <td class="center" style="width:5%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;" v-if="title == '现金流量表(季报)'">本期金额</td>
          <td class="center" style="width:25%;" v-else-if="title == '现金流量表(月报)'">本月金额</td>
        </tr>
      </table>
      <el-scrollbar :style="{height:contentStyleObj}">
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight center bg_color" style="width:45%;">一、经营活动产生的现金流量：</td>
            <td class="center bg_color" style="width:5%;"></td>
            <td style="width:25%;"></td>
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">销售商品、提供劳务收到的现金</td>
            <td class="center bg_color">1</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[1].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[1].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">收到的税费返还</td>
            <td class="center bg_color">2</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[2].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[2].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">收到其他与经营活动有关的现金</td>
            <td class="center bg_color">3</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[3].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[3].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">经营活动现金流入小计</td>
            <td class="center bg_color">4</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[4].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[4].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">购买商品、接受劳务支付的现金</td> 
            <td class="center bg_color">5</td> 
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[5].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[5].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">支付给职工以及为职工支付的现金</td>
            <td class="center bg_color">6</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[6].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[6].periodTotal"></qzf-td>
          </tr>

          <tr>
            <td class="bg_color">支付的各项税费</td>
            <td class="center bg_color">7</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[7].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[7].periodTotal"></qzf-td>
          </tr>

          <tr>
            <td class="bg_color">支付其他与经营活动有关的现金</td>
            <td class="center bg_color">8</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[8].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[8].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">经营活动现金流出小计</td>
            <td class="center bg_color">9</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[9].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[9].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">经营活动产生的现金流量净额</td>
            <td class="center bg_color">10</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[10].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[10].periodTotal"></qzf-td>
          </tr>


          <tr>
            <td class="weight center bg_color">二、投资活动产生的现金流量：</td>
            <td class="center bg_color"></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="bg_color">收回投资收到的现金</td>
            <td class="center bg_color">11</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[12].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[12].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">取得投资收益收到的现金</td>
            <td class="center bg_color">12</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[13].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[13].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">处置固定资产、无形资产和其他非流动资产收回的现金净额</td>
            <td class="center bg_color">13</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[14].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[14].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">处置子公司及其他营业单位收到的现金净额</td>
            <td class="center bg_color">14</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[15].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[15].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">收到其他与投资活动有关的现金</td>
            <td class="center bg_color">15</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[16].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[16].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">投资活动现金流入小计</td>
            <td class="center bg_color">16</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[17].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[17].periodTotal"></qzf-td>
          </tr>

          <tr>
            <td class="bg_color">购建固定资产、无形资产和其他长期资产支付的现金</td>
            <td class="center bg_color">17</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[18].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[18].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">投资支付的现金</td>
            <td class="center bg_color">18</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[19].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[19].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">取得子公司及其他营业单位支付的现金净额</td>
            <td class="center bg_color">19</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[20].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[20].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">支付其他与投资活动有关的现金</td>
            <td class="center bg_color">20</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[21].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[21].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">投资活动现金流出小计</td>
            <td class="center bg_color">21</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[22].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[22].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">投资活动产生的现金流量净额</td>
            <td class="center bg_color">22</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[23].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[23].periodTotal"></qzf-td>
          </tr>


          <tr>
            <td class="weight center bg_color">三、筹资活动产生的现金流量：</td>
            <td class="cente bg_color"></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="bg_color">吸收投资收到的现金</td>
            <td class="center bg_color">23</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[25].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[25].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">取得借款收到的现金</td>
            <td class="center bg_color">24</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[26].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[26].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">收到其他与筹资活动有关的现金</td>
            <td class="center bg_color">25</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[27].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[27].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">筹资活动现金流入小计</td>
            <td class="center bg_color">26</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[28].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[28].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">偿还债务支付的现金</td>
            <td class="center bg_color">27</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[29].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[29].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">分配股利、利润或偿付利息支付的现金</td>
            <td class="center bg_color">28</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[30].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[30].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">支付其他与筹资活动有关的现金</td>
            <td class="center bg_color">29</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[31].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[31].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">筹资活动现金流出小计</td>
            <td class="center bg_color">30</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[32].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[32].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">筹资活动产生的现金流量净额</td>
            <td class="center bg_color">31</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[33].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[33].periodTotal"></qzf-td>
          </tr>

          <tr>
            <td class="weight center bg_color">四、汇率变动对现金及现金等价物的影响</td>
            <td class="center bg_color">32</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[34].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[34].periodTotal"></qzf-td>
          </tr>


          <tr>
            <td class="weight center bg_color">五、现金及现金等价物净增加额</td>
            <td class="center bg_color">33</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[35].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[35].periodTotal"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">加：期初现金及现金等价物余额</td>
            <td class="center bg_color">34</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[36].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[36].periodTotal"></qzf-td>
          </tr>

          <tr>
            <td class="weight center bg_color">六、期末现金及现金等价物余额</td>
            <td class="center bg_color">35</td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[37].yearTotal"></qzf-td>
            <qzf-td :disabled="false" @change="changeList()" v-model:amount="xjllbList[37].periodTotal"></qzf-td>
          </tr>
        </table>
      </el-scrollbar>
    </div>

    <div class="save_box" v-if="this.typeName != '历史数据' && $buttonStatus('kjbb_bc')&& (this.startAccountPeriod == ''?( this.currentPeriod!= ''?true:(this.currentPeriod == this.startAccountPeriod?true:false)):(currentPeriod != ''?(this.currentPeriod == this.startAccountPeriod?true:false):(this.currentPeriod == ''?true:false)))">
      <el-button  @click="saveXjllb" type="primary" size="small"  v-if="this.startAccountPeriod">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span  > 保存</span>
      </el-button>
      <qzf-button  @success="saveXjllb" type="primary" size="small"  v-else>
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span  > 保存</span>
      </qzf-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'cashQuarterTable',
  props: {
    title: String,
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      comName:'',
      taxNo:'',
      xjllbList: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      currentPeriod:this.$store.getters["user/comInfo"].period,
      durationOfTaxTime:""
    }
  },
  created(){
    this.contentStyleObj=this.$getHeight(302)
  },
  methods:{
    changeList(){

    },
    getList(){
      if(this.title == '现金流量表(季报)'){
        if(this.startAccountPeriod){
          let param = {
            tableName: 'kj_xjllb',
            period:this.startAccountPeriod,
            comId:this.comId
          }
          if(this.source){
            param.method = this.$store.getters["commons/params"].initMethod
          }
          gsInfo(param).then(res=>{
            this.durationOfTaxTime = res.data.data.time
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
            if(res.data.data.info && res.data.data.info.length == 38){
              this.xjllbList = res.data.data.info
            }else{
              this.xjllbList = []
            }
          })
        }else{
          gsInfo({tableName: 'kj_xjllb'}).then(res=>{
            if(res.data.data.info  && res.data.data.info.length == 38){
              this.xjllbList = res.data.data.info
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
              this.durationOfTaxTime = res.data.data.time
            }else{
              this.xjllbList = []
            }
          })
        }
      }else if(this.title == '现金流量表(月报)'){
        if(this.startAccountPeriod){
          let param = {
            tableName: 'kj_xjllb',
            period:this.startAccountPeriod,
            comId:this.comId,
            itemName:'month',
          }
          gsInfo(param).then(res=>{
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            if(res.data.data.info  && res.data.data.info.length == 38){
              this.xjllbList = res.data.data.info
            }else{
              ///this.xjllbList = []
            }
          })
        }else{
          gsInfo({tableName: 'kj_xjllb',itemName:'month'}).then(res=>{
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
            if(res.data.data.info  && res.data.data.info.length == 38){
              this.xjllbList = res.data.data.info
            }else{
             // this.xjllbList = []
            }
          })
        }
      }
    },
    saveXjllb(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'kj_xjllb',
          items:this.xjllbList,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        let paramMon = {
          tableName:'kj_xjllb',
          items:this.xjllbList,
          itemName:'month',
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        if(this.title == '现金流量表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '现金流量表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }else{
        let param = {
          tableName:'kj_xjllb',
          items:this.xjllbList
        }
        let paramMon = {
          tableName:'kj_xjllb',
          items:this.xjllbList,
          itemName:'month'
        }
        if(this.title == '现金流量表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '现金流量表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #000;
    font-size: 14px;
  } 
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>